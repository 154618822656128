import React from "react";
import "./iconsAnimation.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faKeyboard,
//   faCodeBranch,
//   faMicrochip,
//   faTerminal,
//   faFolderOpen,
//   faBatteryHalf,
//   faCoffee,
//   faBug,
//   faBolt,
//   faFileAlt,
//   faQrcode,
//   faComments,
//   faEnvelope,
//   faProjectDiagram,
//   faUserSecret,
//   faGlobe,
//   faWindowMaximize,
//   faShieldAlt,
//   faSitemap,
//   faCode,
//   faPowerOff,
//   faServer,
//   faPlug,
//   faHdd,
//   faDesktop,
//   faDatabase,
//   faPrint,
//   faUpload,
//   faCropAlt,
//   faLayerGroup,
//   faUser,
//   faToggleOn,
//   faWifi,
//   faStar,
//   faFolderPlus,
//   faDiceD6,
//   faLock,
//   faSdCard,
//   faUserCircle,
//   faShareAlt,
// } from "@fortawesome/free-solid-svg-icons";
import {
  SiReact,
  SiAngular,
  SiNextdotjs,
  SiNodedotjs,
  SiGatsby,
  SiNpm,
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiGithub,
  SiDocker,
  SiMaterialui,
  SiBootstrap,
  SiGraphql,
  SiStackoverflow,
  SiVisualstudiocode,
  SiPostman,
  SiNetlify,
  SiFirebase,
  SiSketch,
  SiSlack,
  SiAmazonaws,
  SiCodepen,
  SiHasura,
  SiSass,
  SiSwift,
  SiUdemy,
  SiVercel,
  SiTypescript,
} from "react-icons/si";
class IconsAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.lineNumber = 0;
  }
  componentDidMount() {
    // 170 for font awesome icons, 130 for simple icons
    this.lineNumber = Math.floor(
      (Math.sqrt(window.innerWidth) * Math.sqrt(window.innerHeight)) / 130
    );
    this.forceUpdate();
  }

  render = () => {
    let brandIcons = (
      <div>
        <SiReact className="icon" size={60} />
        <SiAngular className="icon" size={60} />
        <SiNextdotjs className="icon" size={60} />
        <SiNodedotjs className="icon" size={60} />
        <SiGatsby className="icon" size={60} />
        <SiNpm className="icon" size={60} />
        <SiHtml5 className="icon" size={60} />
        <SiCss3 className="icon" size={60} />
        <SiSass className="icon" size={60} />
        <SiJavascript className="icon" size={60} />
        <SiTypescript className="icon" size={60} />
        <SiSwift className="icon" size={60} />
        <SiGithub className="icon" size={60} />
        <SiDocker className="icon" size={60} />
        <SiMaterialui className="icon" size={60} />
        <SiBootstrap className="icon" size={60} />
        <SiGraphql className="icon" size={60} />
        <SiStackoverflow className="icon" size={60} />
        <SiNetlify className="icon" size={60} />
        <SiVercel className="icon" size={60} />
        <SiHasura className="icon" size={60} />
        <SiFirebase className="icon" size={60} />
        <SiAmazonaws className="icon" size={60} />
        <SiCodepen className="icon" size={60} />
        <SiVisualstudiocode className="icon" size={60} />
        <SiPostman className="icon" size={60} />
        <SiUdemy className="icon" size={60} />
        <SiSketch className="icon" size={60} />
        <SiSlack className="icon" size={60} />
        {/* repeat */}
        <SiReact className="icon" size={60} />
        <SiAngular className="icon" size={60} />
        <SiNextdotjs className="icon" size={60} />
        <SiNodedotjs className="icon" size={60} />
        <SiGatsby className="icon" size={60} />
        <SiNpm className="icon" size={60} />
        <SiHtml5 className="icon" size={60} />
        <SiCss3 className="icon" size={60} />
        <SiSass className="icon" size={60} />
        <SiJavascript className="icon" size={60} />
        <SiTypescript className="icon" size={60} />
        <SiSwift className="icon" size={60} />
        <SiGithub className="icon" size={60} />
        <SiDocker className="icon" size={60} />
        <SiMaterialui className="icon" size={60} />
        <SiBootstrap className="icon" size={60} />
        <SiGraphql className="icon" size={60} />
        <SiStackoverflow className="icon" size={60} />
        <SiNetlify className="icon" size={60} />
        <SiVercel className="icon" size={60} />
        <SiHasura className="icon" size={60} />
        <SiFirebase className="icon" size={60} />
        <SiAmazonaws className="icon" size={60} />
        <SiCodepen className="icon" size={60} />
        <SiVisualstudiocode className="icon" size={60} />
        <SiPostman className="icon" size={60} />
        <SiUdemy className="icon" size={60} />
        <SiSketch className="icon" size={60} />
        <SiSlack className="icon" size={60} />
      </div>
    );
    // let rowContent = (
    //   <div>
    //     <FontAwesomeIcon className="icon" icon={faKeyboard} />
    //     <FontAwesomeIcon className="icon" icon={faCodeBranch} />
    //     <FontAwesomeIcon className="icon" icon={faMicrochip} />
    //     <FontAwesomeIcon className="icon" icon={faTerminal} />
    //     <FontAwesomeIcon className="icon" icon={faFolderOpen} />
    //     <FontAwesomeIcon className="icon" icon={faBatteryHalf} />
    //     <FontAwesomeIcon className="icon" icon={faCoffee} />
    //     <FontAwesomeIcon className="icon" icon={faBug} />
    //     <FontAwesomeIcon className="icon" icon={faBolt} />
    //     <FontAwesomeIcon className="icon" icon={faFileAlt} />
    //     <FontAwesomeIcon className="icon" icon={faQrcode} />
    //     <FontAwesomeIcon className="icon" icon={faComments} />
    //     <FontAwesomeIcon className="icon" icon={faEnvelope} />
    //     <FontAwesomeIcon className="icon" icon={faProjectDiagram} />
    //     <FontAwesomeIcon className="icon" icon={faUserSecret} />
    //     <FontAwesomeIcon className="icon" icon={faGlobe} />
    //     <FontAwesomeIcon className="icon" icon={faWindowMaximize} />
    //     <FontAwesomeIcon className="icon" icon={faShieldAlt} />
    //     <FontAwesomeIcon className="icon" icon={faSitemap} />
    //     <FontAwesomeIcon className="icon" icon={faCode} />
    //     <FontAwesomeIcon className="icon" icon={faPowerOff} />
    //     <FontAwesomeIcon className="icon" icon={faServer} />
    //     <FontAwesomeIcon className="icon" icon={faPlug} />
    //     <FontAwesomeIcon className="icon" icon={faHdd} />
    //     <FontAwesomeIcon className="icon" icon={faDesktop} />
    //     <FontAwesomeIcon className="icon" icon={faDatabase} />
    //     <FontAwesomeIcon className="icon" icon={faPrint} />
    //     <FontAwesomeIcon className="icon" icon={faUpload} />
    //     <FontAwesomeIcon className="icon" icon={faCropAlt} />
    //     <FontAwesomeIcon className="icon" icon={faLayerGroup} />
    //     <FontAwesomeIcon className="icon" icon={faUser} />
    //     <FontAwesomeIcon className="icon" icon={faToggleOn} />
    //     <FontAwesomeIcon className="icon" icon={faWifi} />
    //     <FontAwesomeIcon className="icon" icon={faStar} />
    //     <FontAwesomeIcon className="icon" icon={faFolderPlus} />
    //     <FontAwesomeIcon className="icon" icon={faDiceD6} />
    //     <FontAwesomeIcon className="icon" icon={faLock} />
    //     <FontAwesomeIcon className="icon" icon={faSdCard} />
    //     <FontAwesomeIcon className="icon" icon={faUserCircle} />
    //     <FontAwesomeIcon className="icon" icon={faShareAlt} />
    //   </div>
    // );
    let topRows = [];
    let bottomRows = [];
    for (let i = 0; i < this.lineNumber; i++) {
      topRows.push(
        <div className="row" key={i}>
          {/* {rowContent} */}
          {brandIcons}
        </div>
      );
      bottomRows.push(
        <div className="row2" key={i}>
          {/* {rowContent} */}
          {brandIcons}
        </div>
      );
    }
    return (
      <div id="iconsSection">
        {topRows}
        <div className="midLine" />
        {bottomRows}
      </div>
    );
  };
}

export default IconsAnimation;
