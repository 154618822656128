import Baffle from "baffle-react";
import React from "react";
import styled, { keyframes } from "styled-components";
import GlitchImage from "../../../components/glitch-image/glitchImage";

class DesktopContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  componentDidMount() {
    if (typeof window !== "undefined" && window.innerWidth <= 1025) {
      this.setState({ show: true });
    }
  }

  render() {
    const HeadingAnimation = keyframes`
    0%{
            top:40%
        }
        100%{
            top:10px
        }
    `;
    const SubHeadingAnimation = keyframes`
    0%{
        top:55%;
        opacity:1;
    }
    100%{
        top:15%;
        opacity:0;
        visibility:hidden
    }
`;
    const ContentTextAnimation = keyframes`
    0%{
        top:60%;
        opacity:0;
    }
    100%{
        top:15%;
        opacity:1;
    }
`;

    const FontColor = keyframes`
            0%   {color:  rgba(4, 229, 229, 1);}
            10%  {color:  rgba(243, 112, 85, 1);}
            20%  {color:  rgba(239, 78, 123, 1);}
            30%  {color:  rgba(161, 102, 171, 1);}
            40% {color:  rgba(80, 115, 184, 1);}
            50% {color:  rgba(4, 229, 229, 1);}
            60% {color:  rgba(7, 179, 155, 1);}
            70% {color:  rgba(111, 186, 130, 1);}
            80% {color:  rgba(80, 115, 184, 1);}
            90% {color:  rgba(16, 152, 173, 1);}
            100% {color:  rgba(243, 112, 85, 1);}
        `;

    const TextContainer = styled.div`
      background-color: #050505;
    `;

    const Text = styled.div`
      /* @media (max-width: 1024px) {
        display: none !important;
      } */

      border-radius: 10px;
      border: 0.1rem solid rgba(4, 229, 229, 0.3);
      /* box-shadow: 0 0 .2rem #fff,
            0 0 .2rem #fff,
            0 0 2rem #04ffff,
            0 0 0.8rem #04ffff,
            0 0 2.8rem #04ffff,
            inset 0 0 1.3rem #04ffff; */

      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      transition: ease-in-out 0.5s;
      text-align: center;
      display: flex;
      &.active {
        transition: ease-in-out 0.5s;
        background-image: linear-gradient(
          to top,
          rgba(4, 229, 229, 0.3),
          rgba(255, 255, 255, 0)
        );
      }
    `;

    const Heading = styled.h4`
      position: absolute;
      top: 40%;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 20px;
      }
      animation: ${FontColor} 10s infinite alternate;
      &.active {
        animation: ${HeadingAnimation} 0.5s forwards,
          ${FontColor} 10s infinite alternate;
        padding-top: 20px;
        animation-fill-mode: forwards;
      }
    `;

    const SubHeading = styled.h5`
      position: absolute;
      top: 55%;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      animation: ${FontColor} 10s infinite alternate;

      @media (max-width: 767px) {
        font-size: 15px;
      }
      &.active {
        animation: ${SubHeadingAnimation} 0.5s forwards;
        top: 15%;
        opacity: 0;
        visibility: hidden;
        animation-fill-mode: forwards;
      }
    `;

    const ContentText = styled.div`
      font-size: 14px;
      font-weight: 300;
      width: 100%;
      position: absolute;
      top: 60%;
      opacity: 0;
      color: #c5c5c5;
      padding: 10px;
      &.active {
        animation: ${ContentTextAnimation} 0.5s forwards;
        top: 15%;
        opacity: 1;
        animation-fill-mode: forwards;
      }
    `;

    const ColorAnimation = keyframes`
      0%  {background: #04e5e5;}
      10% {background: #f37055;}
      20% {background: #ef4e7b;}
      30% {background: #a166ab;}
      40% {background: #5073b8;}
      50% {background: #04e5e5;}
      60% {background: #07b39b;}
      70% {background: #6fba82;}
      80% {background: #5073b8;}
      90% {background: #1098ad;}
      100% {background: #f37055;}
    `;
    const ImageAndTechListContainer = styled.div`
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    `;

    const GlitchImageContainer = styled.div`
      /* flex: 2; */
      align-self: stretch;
      width: 300px;
      height: 300px;
      @media (max-width: 1025px) {
        width: 150px;
        height: 200px;
      }
      @media (max-width: 767px) {
        width: 300px;
        height: 300px;
      }
    `;

    const TechList = styled.ul`
      width: 130px;
      padding: 0;
      display: flex;
      flex-direction: column;
      /* flex: 1; */
    `;

    const TechListElement = styled.li`
      list-style: none;
      color: #c5c5c5;
      font-weight: 300;
      font-size: 14px;
      margin: 5px 0px;
      &::before {
        display: inline-block;
        content: "";
        border-radius: 2.5px;
        height: 5px;
        width: 5px;
        margin-right: 10px;
        position: relative;
        bottom: 2.5px;
        animation: ${ColorAnimation} 10s infinite alternate;
      }
    `;

    return (
      <TextContainer
        onMouseEnter={() => {
          !this.state.show && this.setState({ show: true });
        }}
      >
        <Text
          className={this.state.show ? "active" : ""}
          style={{ display: "flex" }}
        >
          <Heading className={this.state.show ? "active" : ""}>
            {this.props.text}
          </Heading>
          <SubHeading className={this.state.show ? "active" : ""}>
            {this.props.category}
          </SubHeading>
          <ContentText className={this.state.show ? "active" : ""}>
            <Baffle
              speed={50}
              characters="abcdefghijklmnopqrstuvwxyz1234567890"
              obfuscate={false}
              update={true}
              revealDuration={1000}
              revealDelay={0}
            >
              {this.props.description}
            </Baffle>

            <ImageAndTechListContainer>
              <GlitchImageContainer>
                <GlitchImage src={this.props.image} />
              </GlitchImageContainer>
              <TechList>
                {this.props.tech.map((value, index) => {
                  return (
                    <TechListElement key={index}>
                      <Baffle
                        speed={50}
                        characters="abcdefghijklmnopqrstuvwxyz1234567890"
                        obfuscate={false}
                        update={true}
                        revealDuration={1000}
                        revealDelay={0}
                      >
                        {value}
                      </Baffle>
                    </TechListElement>
                  );
                })}
              </TechList>
            </ImageAndTechListContainer>
          </ContentText>
        </Text>
      </TextContainer>
    );
  }
}

export default DesktopContent;
