import React from "react";
import styled, { keyframes } from "styled-components";

class ContactThreeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      error: false,
      status: "",
    };
  }

  formSubmit = (ev) => {
    console.log(this.state);
    if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
      ev.preventDefault();

      this.setState({ error: true });
      this.forceUpdate();
    } else {
      this.setState({ error: false });

      ev.preventDefault();
      const form = ev.target;
      const data = new FormData(form);
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          this.setState({ status: "SUCCESS" });
        } else {
          this.setState({ status: "ERROR" });
        }
        this.forceUpdate();
      };
      xhr.send(data);
    }
  };

  check(val) {
    if (this.state.error && val === "") {
      return false;
    } else {
      return true;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    const Separator = styled.div`
      height: 5px;
      width: 50px;
      background-color: #04e5e5;
      margin-bottom: 30px;
      margin-left: 6px;
    `;

    const ContactForm = styled.div`
      padding: 40px;
      width: 100%;
      position: relative;
      z-index: 5;
    `;

    const Heading = styled.h2`
      font-size: 70px;
      font-family: Teko;
      color: #fff;
    `;

    const InputElement = styled.div`
      margin: 20px 0;
    `;

    const ErrorInputAnimation = keyframes`
            0% {
              border-bottom: 1px solid #111;
            }
            100% {
              border-bottom: 1px solid #ff0000;
            }
        `;

    const Input = styled.input`
      width: 100%;
      background-color: #111;
      border: none;
      border-bottom: 1px solid #444;
      padding: 10px 5px;
      border-radius: 0;
      color: #fff;
      transition: 0.5s;
      &:focus {
        border-bottom: 1px solid #04e5e5;
        outline: none;
      }
      &.error {
        animation: ${ErrorInputAnimation} 1s forwards;
      }
    `;

    const Textarea = styled.textarea`
      width: 100%;
      background-color: #111;
      border: none;
      border-bottom: 1px solid #444;
      padding: 10px 5px;
      border-radius: 0;
      color: rgb(255, 255, 255);
      transition: all 0.5s ease 0s;
      min-height: 100px;
      margin-top: 0px;
      margin-bottom: 0px;
      height: 100px;
      &:focus {
        border-bottom: 1px solid #04e5e5;
        outline: none;
      }
    `;

    const Submit = styled.button`
      display: block;
      height: 50px;
      width: 186px;
      position: relative;
      border: none;
      overflow: hidden;
      transition: 0.5s;
      background-color: #000;
      &:hover {
        background: #444;
      }
      span {
        position: relative;
        top: 0;
        color: #fff;
        z-index: 10;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    `;

    const StatusMessageSuccess = styled.p`
      color: #fff;
    `;

    const StatusMessageFailed = styled.p`
      color: red;
    `;

    return (
      <ContactForm>
        <form onSubmit={this.formSubmit} action="https://formspree.io/f/meqpvjeo" method="POST">
          <Heading>Get In Touch</Heading>
          <Separator />
          <InputElement>
            <Input
              type="text"
              name="name"
              defaultValue={this.state.name}
              className={`name ${this.check(this.state.name) ? "" : "error"}`}
              placeholder="Name"
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </InputElement>
          <InputElement>
            <Input
              type="text"
              name="email"
              defaultValue={this.state.email}
              className={`email ${this.check(this.state.email) ? "" : "error"}`}
              placeholder="Email"
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </InputElement>
          <InputElement>
            <Input type="text" name="phone" defaultValue={this.state.phone} className="phone" placeholder="Phone(Optional)" onChange={(e) => this.setState({ phone: e.target.value })} />
          </InputElement>
          <InputElement>
            <Textarea
              placeholder="Message"
              name="message"
              defaultValue={this.state.message}
              className={`message ${this.check(this.state.message) ? "" : "error"}`}
              onChange={(e) => this.setState({ message: e.target.value })}
            />
          </InputElement>
          {this.state.status === "SUCCESS" ? (
            <StatusMessageSuccess>Thanks!</StatusMessageSuccess>
          ) : (
            <Submit
              // onClick={() => this.formSubmit()}
              type="submit"
            >
              <span>Submit</span>
            </Submit>
          )}
          {this.state.status === "ERROR" && <StatusMessageFailed>Invalid input. Please check again.</StatusMessageFailed>}
        </form>
      </ContactForm>
    );
  }
}

export default ContactThreeForm;
