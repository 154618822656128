import React from "react";
import Tilt from "react-tilt";
import styled, { keyframes } from "styled-components";
import DesktopContent from "./DesktopContent.js";
class PortfolioItem extends React.Component {
  render() {
    const MoveUp = keyframes`
            0% { transform: translateY(10px); }
            100% { transform: translateY(-10px); }
        `;

    const MoveDown = keyframes`
            0% { transform: translateY(-10px); }
            100% { transform: translateY(10px); }
        `;

    const Text = styled.div`
      transform: translateY(50px);
      transition: 0.5s;
      width: 100%;
      text-align: center;
    `;
    const Heading = styled.h4`
      color: #fff;
      font-weight: 600;
      font-size: 25px;
    `;

    const SubHeading = styled.h5`
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
    `;

    const Item = styled.div`
      position: relative;
      overflow: hidden;
      max-width: 95%;
      margin: 45px auto;
      border-radius: 10px;
      height: 500px;

      @media (max-width: 1611px) {
        height: 700px;
      }
      @media (max-width: 1025px) {
        height: 700px;
      }
      @media (max-width: 603px) {
        height: 700px;
      }
      &.move-up {
        animation: ${MoveUp} 5s infinite alternate;
      }
      &.move-down {
        animation: ${MoveDown} 5s infinite alternate;
      }
      &:hover {
        ${Text} {
          transform: translateY(-10px);
        }
        box-shadow: 0 28px 60px rgb(4, 229, 229, 0.5);
      }

      box-shadow: 0 28px 60px rgb(4, 229, 229, 0.2);
      transition: 0.5s;
    `;

    const Content = styled.div`
      margin-bottom: 20px;
      /* min-height: 450px; */
      min-height: auto;
    `;

    const item = (
      <Item
        className={`${this.props.index % 2 === 0 ? "move-up" : "move-down"}`}
      >
        <Content>
          <DesktopContent
            text={this.props.text}
            category={this.props.category}
            description={this.props.description}
            tech={this.props.tech}
            type={this.props.type}
            image={this.props.image}
          />
        </Content>
      </Item>
    );

    return (
      <a href={this.props.link} target="_blank" rel="noopener noreferrer">
        {typeof window !== "undefined" && window.innerWidth <= 1025 ? (
          item
        ) : (
          <Tilt options={{ scale: 1.1, max: 10, reverse: true }}>{item}</Tilt>
        )}
      </a>
    );
  }
}

export default PortfolioItem;
