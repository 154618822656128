import AnimatedHeading from "components/animated-heading";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PortfolioItem from "sections/portfolio/parts/PortfolioItem.js";
import styled from "styled-components";

class PortfolioOne extends React.Component {
  render() {
    const Section = styled.section`
      background-color: #050505;
      padding: 100px 0;
    `;
    const PortfolioContainer = styled.div`
      height: 100%;
      padding: 0 50px;
      /* @media (min-width: 1530px) {
        margin-left: 200px;
        margin-right: 200px;
      } */
      @media (max-width: 768px) {
        padding: 0 50px;
      }
      @media (max-width: 420px) {
        padding: 0 10px;
      }
    `;

    return (
      <Section id="portfolio">
        <Col md={12}>
          <Container>
            <AnimatedHeading text="Recent Projects" />
          </Container>
          <PortfolioContainer>
            <Row>{this.portfolio()}</Row>
          </PortfolioContainer>
        </Col>
      </Section>
    );
  }

  portfolio() {
    const { items } = this.props;
    return items.map((value, index) => {
      return (
        <Col md={4} key={value.frontmatter.id}>
          <PortfolioItem
            index={index}
            image={value.frontmatter.image.publicURL}
            text={value.frontmatter.title}
            category={value.frontmatter.category}
            link={value.frontmatter.link}
            description={value.frontmatter.description}
            tech={value.frontmatter.tech}
            type="col"
          />
        </Col>
      );
    });
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        items: allMarkdownRemark(
          sort: { fields: frontmatter___id, order: ASC }
          limit: 6
          filter: { fileAbsolutePath: { regex: "/content/portfolio/" } }
        ) {
          nodes {
            frontmatter {
              category
              link
              title
              id
              description
              tech
              image {
                publicURL
              }
            }
          }
        }
      }
    `}
    render={({ items }) => <PortfolioOne items={items.nodes} {...props} />}
  />
);
