import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

export class Eyes extends Component {
  constructor(props) {
    super(props);
    this.Eye1 = React.createRef();
    this.Eye2 = React.createRef();
  }

  componentDidMount() {
    document
      .querySelector('body')
      .addEventListener('mousemove', (e) => this.eyeball(e));
  }

  componentWillUnmount() {
    document
      .querySelector('body')
      .removeEventListener('mousemove', (e) => this.eyeball(e));
  }
  eyeball = (e) => {
    var eye = document.querySelectorAll('.eye');
    eye.forEach((eye) => {
      let x = eye.getBoundingClientRect().left + eye.clientWidth / 2;
      let y = eye.getBoundingClientRect().top + eye.clientHeight / 2;
      let radian = Math.atan2(e.clientX - x, e.clientY - y);
      let rot = radian * (180 / Math.PI) * -1 + 270;
      eye.style.transform = 'rotate(' + rot + 'deg)';
    });
  };

  render() {
    const Eyes = styled.div`
      cursor: pointer;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
    `;

    const Screen = styled.div`
      position: absolute;
      width: 100vw;
      height: 100vh;
      z-index: -100;
    `;

    const Eye1 = styled.div`
      position: absolute;
      top: ${(this.props.imgHeight * 30) / 100}px;
      left: ${(this.props.imgWidth * 35) / 100}px;
      width: ${this.props.imgWidth / 15}px;
      height: ${this.props.imgWidth / 15}px;
      margin: 0 ${this.props.imgWidth / 75}px;
      background: #fff;

      border-radius: 50%;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: ${this.props.imgWidth / 50}px;
        transform: translate(-50%, -50%);
        width: ${this.props.imgWidth / 30}px;
        height: ${this.props.imgWidth / 30}px;
        background: #000;
        border-radius: 50%;
      }
    `;
    const Eye2 = styled.div`
      position: absolute;
      top: ${(this.props.imgHeight * 28) / 100}px;
      left: ${(this.props.imgWidth * 57) / 100}px;
      width: ${this.props.imgWidth / 15}px;
      height: ${this.props.imgWidth / 15}px;
      margin: 0 ${this.props.imgWidth / 75}px;
      background: #fff;
      border-radius: 50%;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: ${this.props.imgWidth / 50}px;
        transform: translate(-50%, -50%);
        width: ${this.props.imgWidth / 30}px;
        height: ${this.props.imgWidth / 30}px;
        background: #000;
        border-radius: 50%;
      }
    `;
    //!!! big eye version:
    // const Eye1 = styled.div`
    //   position: absolute;
    //   top: ${(this.props.imgHeight * 30) / 100}px;
    //   left: ${(this.props.imgWidth * 33) / 100}px;
    //   width: ${this.props.imgWidth / 10}px;
    //   height: ${this.props.imgWidth / 10}px;
    //   margin: 0 ${this.props.imgWidth / 75}px;
    //   background: #fff;

    //   border-radius: 50%;
    //   &:before {
    //     content: '';
    //     position: absolute;
    //     top: 50%;
    //     left: ${this.props.imgWidth / 30}px;
    //     transform: translate(-50%, -50%);
    //     width: ${this.props.imgWidth / 20}px;
    //     height: ${this.props.imgWidth / 20}px;
    //     background: #000;
    //     border-radius: 50%;
    //   }
    // `;
    // const Eye2 = styled.div`
    //   position: absolute;
    //   top: ${(this.props.imgHeight * 28) / 100}px;
    //   left: ${(this.props.imgWidth * 56) / 100}px;
    //   width: ${this.props.imgWidth / 10}px;
    //   height: ${this.props.imgWidth / 10}px;
    //   margin: 0 ${this.props.imgWidth / 75}px;
    //   background: #fff;

    //   border-radius: 50%;
    //   &:before {
    //     content: '';
    //     position: absolute;
    //     top: 50%;
    //     left: ${this.props.imgWidth / 30}px;
    //     transform: translate(-50%, -50%);
    //     width: ${this.props.imgWidth / 20}px;
    //     height: ${this.props.imgWidth / 20}px;
    //     background: #000;
    //     border-radius: 50%;
    //   }
    // `;

    return (
      <Eyes>
        <Screen />
        <Eye1 className='eye' ref={this.Eye1} />
        <Eye2 className='eye' ref={this.Eye2} />
      </Eyes>
    );
  }
}

export default Eyes;
