import React, { Component } from 'react';
import './scroll-bar.css';
export class ScrollBar extends Component {
  componentDidMount() {
    let progressbar = document.getElementById('progressbar');
    let totalHeight = document.body.scrollHeight;
    window.onscroll = function () {
      //Dont know why need to mulply 10/9
      let progress = (window.pageYOffset / totalHeight) * 100 * (10 / 9);
      progressbar.style.height = progress + '%';
    };
  }
  render() {
    return (
      <React.Fragment>
        <div id='progressbar'></div>
        <div id='scrollPath'></div>
      </React.Fragment>
    );
  }
}

export default ScrollBar;
