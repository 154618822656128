import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "components/layout";
import HeroPersonal from "sections/hero/HeroPersonal.js";
import AboutPersonal from "sections/about/AboutPersonal.js";
import ServicesThree from "sections/services/ServicesThree.js";
import PortfolioOne from "sections/portfolio/PortfolioOne.js";
// import PortfolioTwo from "sections/portfolio/PortfolioTwo.js";
// import PortfolioThree from "sections/portfolio/PortfolioThree.js";
// import TestimonialsThree from 'sections/testimonials/TestimonialsThree.js';
// import ClientsThree from 'sections/clients/ClientsThree.js';
import ContactPersonal from "sections/contact/ContactPersonal.js";
// import ParticlesContainer from '../components/particles/ParticlesContainer';
// import IconsAnimation from '../components/iconsAnimation/iconsAnimation';
// import {
//   isBrowser,
//   isMobile,
//   isChrome,
//   isIE,
//   isEdge,
// } from 'react-device-detect';
import ScrollBar from "../components/scroll-bar/scroll-bar";
// import { isBrowser } from "react-device-detect";
class Index extends React.Component {
  render() {
    const { site } = this.props.data;

    let scrollBar;
    scrollBar = <ScrollBar />;
    return (
      <div>
        <Helmet>
          <title>{site.meta.title}</title>
          <meta name="description" content={site.meta.description} />
          <meta name="keywords" content={site.meta.keywords} />
        </Helmet>
        <Layout
          isHome={true}
          sections={["home", "about", "services", "portfolio", "contact"]}
        >
          {typeof window !== "undefined" &&
            window.innerWidth > 1000 &&
            scrollBar}
          {/* <Layout
          isHome={true}
          sections={['home', 'about', 'services', 'portfolio', 'testimonials', 'clients', 'contact']}
        > */}
          <HeroPersonal />
          <AboutPersonal />
          <ServicesThree />
          <PortfolioOne />
          {/* <PortfolioTwo />
          <PortfolioThree /> */}
          {/* <TestimonialsThree /> */}
          {/* <ClientsThree /> */}
          <ContactPersonal />
        </Layout>
        {/* <ParticlesContainer /> */}
      </div>
    );
  }
}

export default Index;

export const personalQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
        keywords
      }
    }
  }
`;
