import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

class Footer extends React.Component {
  render() {
    const FooterMain = styled.div`
      display: flex;
      flex-direction: column;
      background-color: #111;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    `;

    const SocialIcons = styled.div`
      .social_icon {
        font-size: 15px;
        color: #555;
        margin: 0 5px;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
          color: #04e5e5;
        }
      }
    `;

    const AuthorInfo = styled.div`
      font-size: 12px;
      font-weight: 300;
      color: #04e5e5;
    `;

    return (
      <FooterMain>
        <AuthorInfo>
          Created by Kai Qiu. Copyright ©️ 2021 All rights reserved
        </AuthorInfo>
        <SocialIcons>
          <FontAwesomeIcon
            icon={faGithub}
            className="social_icon"
            onClick={() => window.open("https://github.com/human2l")}
          />
          {/* <FontAwesomeIcon icon={faTwitter} className="social_icon" onClick={() => window.open("https://www.twitter.com")} />
          <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open("https://www.youtube.com")} /> */}
          <FontAwesomeIcon
            icon={faLinkedin}
            className="social_icon"
            onClick={() =>
              window.open("https://www.linkedin.com/in/kai-qiu-3a130615a/")
            }
          />
        </SocialIcons>
        {/* <div>
          Icons made by{" "}
          <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">
            Smashicons
          </a>
          {" , "}
          <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">
            Pixel perfect
          </a>{" "}
          and{" "}
          <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">
            smalllikeart
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div> */}
      </FooterMain>
    );
  }
}
export default Footer;
