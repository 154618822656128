import React from "react";
import styled, { keyframes } from "styled-components";
import Progress from "components/progress";
import Timeline from "sections/about/parts/Timeline";

class TabsPart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "skills",
    };
  }

  render() {
    const TabContainer = styled.div`
      min-height: 350px;
      margin-top: 20px;
      @media (max-width: 767px) {
        margin-top: 50px;
        padding: 0 20px;
      }
    `;
    const TabSelectors = styled.div`
      display: flex;
      @media (max-width: 410px) {
        justify-content: space-evenly;
      }
    `;

    const ColorAnimation = keyframes`
            0%  {border-color: #04e5e5;}
            10% {border-color: #f37055;}
            20% {border-color: #ef4e7b;}
            30% {border-color: #a166ab;}
            40% {border-color: #5073b8;}
            50% {border-color: #04e5e5;}
            60% {border-color: #07b39b;}
            70% {border-color: #6fba82;}
            80% {border-color: #5073b8;}
            90% {border-color: #1098ad;}
            100% {border-color: #f37055;}
        `;

    const TabSelector = styled.button`
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      background-color: transparent;
      border: none;
      margin: 0 10px 0 0;
      border-bottom: 2px solid #fff;
      transition: 0.5s;
      &:hover,
      &.active {
        animation: ${ColorAnimation} 10s infinite alternate;
      }
      &:focus {
        outline: none;
      }
      @media (max-width: 767px) {
        font-size: 18px;
      }
    `;

    const Tabs = styled.div`
      margin-top: 20px;
    `;

    const Fade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `;

    const Tab = styled.div`
      display: none;
      animation: ${Fade} 1s forwards;
    `;

    const SkillTab = styled.div`
      display: none;

      animation: ${Fade} 1s forwards, ${ColorAnimation} 10s infinite alternate;
    `;

    //     const ColorAnimationText = keyframes`
    // 0%  {color: #04e5e5;}
    // 10% {color: #f37055;}
    // 20% {color: #ef4e7b;}
    // 30% {color: #a166ab;}
    // 40% {color: #5073b8;}
    // 50% {color: #04e5e5;}
    // 60% {color: #07b39b;}
    // 70% {color: #6fba82;}
    // 80% {color: #5073b8;}
    // 90% {color: #1098ad;}
    // 100% {color: #f37055;}
    // `;

    const SkillText = styled.div`
      margin-top: 10px;
      margin-right: 20px;
      color: #fff;
    `;

    return (
      <TabContainer>
        <TabSelectors>
          <TabSelector
            className={this.state.tab === "skills" ? "active" : ""}
            onClick={() => this.setState({ tab: "skills" })}
          >
            Skills
          </TabSelector>
          <TabSelector
            className={this.state.tab === "experience" ? "active" : ""}
            onClick={() => this.setState({ tab: "experience" })}
          >
            {typeof window !== "undefined" && window.innerWidth > 410
              ? "Experience"
              : "Exp"}
          </TabSelector>
          <TabSelector
            className={this.state.tab === "education" ? "active" : ""}
            onClick={() => this.setState({ tab: "education" })}
          >
            {typeof window !== "undefined" && window.innerWidth > 410
              ? "Education"
              : "Edu"}
          </TabSelector>
        </TabSelectors>

        <SkillTab
          style={
            this.state.tab === "skills"
              ? {
                  borderLeft: "2px solid white",
                  borderRight: "2px solid white",
                  height: "250px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                  alignContent: "stetch",
                  justifyContent: "space-evenly",
                }
              : {
                  display: "none",
                }
          }

          // style={{
          //   display: this.state.tab === "skills" ? "block" : "none",
          // }}
        >
          <SkillText>Next.js</SkillText>
          <SkillText>Gatsby</SkillText>
          <SkillText>React</SkillText>
          <SkillText>Angular</SkillText>
          <SkillText>HTML</SkillText>
          <SkillText>CSS</SkillText>
          <SkillText>NodeJS</SkillText>
          <SkillText>Express</SkillText>
          <SkillText>MySQL</SkillText>
          <SkillText>PostgreSQL</SkillText>
          <SkillText>Redis</SkillText>
          <SkillText>Swift</SkillText>
          <SkillText>Java</SkillText>
          <SkillText>React Native</SkillText>
        </SkillTab>

        <Tabs>
          <Tab
            style={{
              display: this.state.tab === "experience" ? "block" : "none",
            }}
          >
            <Timeline
              data={{
                "04/2020": {
                  title: "Full Stack Developer Intern",
                  institution: "JIBB",
                  description:
                    "JIBB provide products that implemented deep learning and motion detection technology to capture user’s handwriting on whiteboard/paper from the camera and convert it into a digital version for online meeting purpose.I developed a web prototype of JIBB product that combined both JIBB and CISCO Webex technology. This makes it possible for the user to using JIBB products through Webex meetings. I developed a desktop application as an image processing toolkit by using Java and JavaFX. This image processing tool replaced the legacy python tools and became one part of the internal developing infrastructure using by all developers in the company.",
                },
                // "11/2020": {
                //   title: "Web Developer",
                //   institution: "Totally Pets",
                //   description: "",
                // },
              }}
            />
          </Tab>
          <Tab
            style={{
              display: this.state.tab === "education" ? "block" : "none",
            }}
          >
            <Timeline
              data={{
                "2012": {
                  title: "Bachelor of Commerce in Accounting",
                  institution: "Shandong Institute of Business and Technology",
                  description: "",
                },
                "2018": {
                  title: "Bachelor of Science in Information Technology",
                  institution: "University of Technology Sydney",
                  description: "",
                },
              }}
            />
          </Tab>
        </Tabs>
      </TabContainer>
    );
  }
}

export default TabsPart;
